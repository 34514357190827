<template>
  <div>
    <div :class="[title ? 'justify-sm-space-between' : '']" class="d-flex flex-column flex-sm-row align-center justify-center py-4">
      <div
        v-if="title"
        class="d-flex align-center body-2 font-weight-bold c-pointer"
        @click.prevent=""
      >
        <v-icon color="primary" class="mr-2" small>
          {{ view === 'calendar' ? 'mdi-calendar-clock' : 'mdi-view-list-outline' }}
        </v-icon>
        {{ title || '' }}
      </div>
      <div class="d-flex flex-column-reverse flex-sm-row align-center pt-4 pt-sm-0">
        <div class="d-flex align-center booking-filter-month">
          <v-btn icon small @click.prevent="$refs[refCalendar].prev()">
            <v-icon>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-select
            v-model="selectedMonth"
            :items="ListOfYear()"
            item-text="text"
            item-value="value"
            color="primary"
            solo
            hide-details
            dense
            class="mx-4 rounded-pill select-calendar-month"
            append-icon="mdi-update"
            @click:append="focus = $localDT()"
          />
          <v-btn icon small @click.prevent="$refs[refCalendar].next()">
            <v-icon>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <div class="">
      <div class="p-relative">
        <v-calendar
          :ref="refCalendar"
          v-model="focus"
          :events="events"
          :weekdays="[1, 2, 3, 4, 5, 6, 0]"
          start="2022-01-01"
          end="2022-02-31"
          color="primary"
          type="month"
          @change="updateRange"
          @click:event="showEvent"
        >
          <template v-slot:event="{ event }">
            <v-tooltip bottom content-class="caption primary rounded-pill" dark>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="booking-counter"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span :class="[parseInt(event.name) > 75 ? 'red' : (parseInt(event.name) > 50 ? 'orange' : 'secondary')]" :style="{ width: event.name + '%' }" class="booking-counter-progress" />
                  <span v-if="event.isPrice">
                    <span v-if="parseInt(room.id)" :class="[event.priceActive === 'price-by-date' ? 'green--text' : (event.priceActive === 'price-week' ? 'indigo--text' : 'black--text')]">
                      {{ event.name + (event.isPrice ? '' : '%') }}
                    </span>
                    <span v-else>
                      {{ event.name + (event.isPrice ? '' : '%') }}
                    </span>
                  </span>
                  <span v-else>
                    {{ event.name + (event.isPrice ? '' : '%') }}
                  </span>
                </div>
              </template>
              <span v-if="event.isPrice">
                <span v-if="parseInt(room.id)">
                  <span v-if="event.priceActive === 'price-by-date'">
                    {{ ('Price (daily) ' + event.name) }}
                  </span>
                  <span v-else-if="event.priceActive === 'price-week'">
                    {{ ('Price (weekly) ' + event.name) }}
                  </span>
                  <span v-else>
                    {{ ('Price (default) ' + event.name) }}
                  </span>
                </span>
                <span v-else>
                  Booking profit
                </span>
              </span>
              <span v-else-if="parseInt(room.id)">
                {{ event.name + '%' }} rooms occupied ({{ event.scheduled }}/{{ parseInt(event.roomTotal || 0) }})
              </span>
              <span v-else>
                <!-- FOR DISPLAY SUMMARY BOOKING & ORDER -->
                {{ event.name + '%' }} rooms occupied (0/{{ parseInt(event.roomTotal || 0) }})
              </span>
            </v-tooltip>
          </template>
        </v-calendar>
        <v-menu
          v-model="openDateMenu"
          :close-on-content-click="false"
          :activator="openDateElement"
          :nudge-top="-5"
          offset-y
        >
          <v-card
            color="grey lighten-4"
            min-width="250px"
            flat
          >
            <v-card-title class="secondary white--text py-2 body-2">
              {{ $localDT(openDateEvent.start, 'displaylocal') }}
            </v-card-title>
            <v-card-text class="pb-3 pt-3">
              <div v-if="openDateEvent.isPrice && parseInt(room.id)">
                <v-text-field
                  v-model.number="priceToUpdate"
                  :disabled="$IsPast(openDateEvent.start)"
                  hide-details
                  label="Update Price"
                  outlined
                  dense
                  @keypress="$NumberOnly"
                />
              </div>
              <div v-else>
                {{ openDateEvent.name }}:
              </div>
            </v-card-text>
            <v-divider />
            <v-card-actions class="justify-end">
              <v-btn
                text
                x-small
                color="red"
                class="text-capitalize font-weight-bold"
                @click="openDateMenu = false"
              >
                Close
              </v-btn>
              <v-btn
                v-if="openDateEvent.isPrice && parseInt(room.id)"
                :disabled="$IsPast(openDateEvent.start)"
                outlined
                x-small
                color="secondary"
                class="ml-4 text-capitalize font-weight-bold"
                @click="UpdatePrice()"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
      <div class="d-flex fill-width justify-center justify-sm-end pt-4">
        <v-btn depressed small class="text-capitalize" dark color="indigo" @click.prevent="BulkUpdateOpen()">
          Bulk Update
        </v-btn>
      </div>
    </div>
    <v-dialog
      v-model="dialogBulkUpdate.show"
      scrollable
      persistent
      max-width="420"
    >
      <v-card>
        <v-card-title class="body-1 justify-center font-weight-bold">
          {{ room.name }} - Bulk Update
        </v-card-title>
        <v-divider />
        <v-card-text class="px-4 py-2" style="max-height: 75vh;">
          <div v-if="!dialogBulkUpdate.data.length" class="px-4 py-8 text-center">
            No data
          </div>
          <div v-if="dialogBulkUpdate.data.length" class="d-flex align-center fill-width py-2">
            <v-menu
              ref="menu"
              v-model="menu"
              :return-value.sync="dates"
              left
              offset-y
              :close-on-content-click="false"
              min-width="auto"
            >
              <template #activator="{ on, attrs }">
                <div class="d-flex align-center justify-center font-weight-bold rounded fill-width grey lighten-4 px-3 py-2 mb-1" v-bind="attrs" v-on="on">
                  <span class="">{{ dateRangeText }}</span>
                  <v-icon small class="ml-1">
                    mdi-chevron-down
                  </v-icon>
                </div>
              </template>
              <v-date-picker
                v-model="dates"
                :allowed-dates="(r) => $localDT(new Date(r), 'datedefault') > $localDT(new Date(), 'datedefault')"
                no-title
                light
                class="force-light-font"
                range
              >
                <v-spacer />
                <v-btn
                  text
                  class="text-capitalize"
                  small
                  @click="menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  :disabled="dates.length !== 2"
                  text
                  class="text-capitalize"
                  small
                  @click="dates = $dateFromTo(dates), $refs.menu.save(dates), (dates.length === 2 ? BulkUpdateOpen(dates) : '')"
                >
                  Ok
                </v-btn>
              </v-date-picker>
            </v-menu>
          </div>
          <div v-if="dialogBulkUpdate.data.length" class="d-flex justify-space-between align-center py-2">
            <div class="pr-2" style="width: 50%;">
              <v-text-field
                v-model.number="dialogBulkUpdate.bulkRoomTotal"
                suffix="room"
                label="Bulk - Availability"
                outlined
                dense
                hide-details
                color="secondary"
                class="elevation-0"
                @keypress="$NumberOnly"
              />
            </div>
            <div class="pl-2" style="width: 50%;">
              <v-text-field
                v-model.number="dialogBulkUpdate.bulkRoomPrice"
                label="Bulk - Price"
                prefix="Rp"
                outlined
                dense
                hide-details
                color="secondary"
                class="elevation-0"
                @keypress="$NumberOnly"
              />
            </div>
          </div>
          <div v-if="dialogBulkUpdate.data.length" class="d-flex fill-width align-center justify-center">
            <span class="c-pointer orange--text caption font-weight-bold pb-2" @click.prevent="ResetDefault">
              Reset to Default
            </span>
          </div>
          <div v-for="(ba, iBa) in dialogBulkUpdate.data" :key="'pricing-bulk-list-' + iBa" class="d-flex fill-width align-center justify-start justify-sm-space-between pa-2 my-2 grey lighten-4 rounded-lg">
            <div class="d-flex align-center fill-width subtitle-1 pr-2">
              <v-icon class="mr-2" color="secondary">
                mdi-calendar
              </v-icon>
              <div>
                {{ $localDT(ba.price_date, 'displaylocal') }}
              </div>
            </div>
            <v-text-field
              v-model.number="dialogBulkUpdate.data[iBa].availability"
              suffix="room"
              solo
              dense
              hide-details
              color="secondary"
              class="elevation-0"
              @keypress="$NumberOnly"
            />
            <div class="pl-2" style="min-width: 125px;">
              <v-text-field
                v-model.number="dialogBulkUpdate.data[iBa].price"
                solo
                dense
                hide-details
                prefix="Rp"
                color="secondary"
                class="elevation-0 fill-width"
                @click="parseInt(dialogBulkUpdate.data[iBa].price) ? '' : (dialogBulkUpdate.data[iBa].price === 'Default' ? dialogBulkUpdate.data[iBa].price = 0 : dialogBulkUpdate.data[iBa].price = 'Default')"
                @blur="parseInt(dialogBulkUpdate.data[iBa].price) ? '' : dialogBulkUpdate.data[iBa].price = 'Default'"
                @keypress="$NumberOnly"
              />
            </div>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            :disabled="dialogBulkUpdate.loading"
            color="red darken-1"
            text
            class="text-capitalize font-weight-bold"
            @click="dialogBulkUpdate.show = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            :loading="dialogBulkUpdate.loading"
            color="secondary darken-1"
            depressed
            dark
            class="text-capitalize font-weight-bold px-4"
            @click="BulkUpdateProcess()"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'Calendar',
  props: {
    refCalendar: {
      type: String,
      default: 'calendar'
    },
    title: {
      type: String,
      default: null
    },
    room: {
      type: Object,
      default: () => {}
    },
    booking: {
      type: Object,
      default: () => {}
    }
  },
  data: vm => ({
    priceToUpdate: 0,
    view: 'calendar',
    selectedMonth: vm.$localDT(),
    focus: vm.$localDT(),
    openDateMenu: false,
    openDateEvent: {},
    openDateElement: null,
    type: 'month',
    events: [],
    data: {
      pricing: [],
      schedules: []
    },
    dialogBulkUpdate: {
      show: false,
      loading: false,
      bulkRoomTotal: 0,
      bulkRoomPrice: 0,
      dataTemp: [],
      data: []
    },
    menu: false,
    dates: vm.$dateFromTo([vm.$localDT(new Date(), 'datedefault'), vm.$localDT(new Date(), 'datedefault')])
  }),
  computed: {
    dateRangeText () {
      return this.dates.map(r => this.formatDate(r)).join(' - ')
    }
  },
  watch: {
    // dates (v) {
    //   console.log(v)
    // },
    'dialogBulkUpdate.bulkRoomTotal' (v) {
      if (parseInt(v)) {
        console.log('t1')
        this.dialogBulkUpdate.data.map((r) => {
          r.availability = parseInt(v) || 0
          return r
        })
      } else {
        console.log('t2')
        this.dialogBulkUpdate.data = this.dialogBulkUpdate.dataTemp
      }
    },
    'dialogBulkUpdate.bulkRoomPrice' (v) {
      if (parseInt(v)) {
        this.dialogBulkUpdate.data.map((r) => {
          r.price = parseInt(v) || 0
          return r
        })
      } else {
        this.dialogBulkUpdate.data = this.dialogBulkUpdate.dataTemp
      }
    },
    selectedMonth (v) {
      this.focus = v
    }
  },
  mounted () {
    this.$refs[this.refCalendar].checkChange()
  },
  methods: {
    ResetDefault () {
      this.dialogBulkUpdate.bulkRoomTotal = 0
      this.dialogBulkUpdate.bulkRoomPrice = 0
      this.dialogBulkUpdate.data.map((r) => {
        r.availability = 0
        r.price = 0
        return r
      })
    },
    BulkUpdateOpen (fromTo) {
      // OPEN BULK UPDATE BY LIST DATES
      this.dialogBulkUpdate.data = []
      this.dialogBulkUpdate.bulkRoomTotal = 0
      this.dialogBulkUpdate.bulkRoomPrice = 0
      this.dialogBulkUpdate.dataTemp = []
      this.dialogBulkUpdate.data = []
      const thisMonth = this.$getThisMonth(this.selectedMonth)
      if (!fromTo) {
        this.dates = [thisMonth.start, thisMonth.end]
      }
      const dayLongBooking = this.$DateRangeDays((fromTo ? fromTo[0] || thisMonth.start : thisMonth.start), (fromTo ? fromTo[1] || thisMonth.end : thisMonth.end)) + 1
      for (let b = 0; b < dayLongBooking; b++) {
        const d = new Date(fromTo ? fromTo[0] : thisMonth.start)
        d.setDate(d.getDate() + b)
        const dString = d.toLocaleDateString().split('/')
        if (dString.length === 3) {
          const year = parseInt(dString[2])
          const month = ('0' + parseInt(dString[0])).slice(-2)
          const date = ('0' + parseInt(dString[1])).slice(-2)
          const newDate = year + '-' + month + '-' + date
          if (new Date(newDate).getTime() > new Date().getTime()) {
            // let activePrice = (this.$isWeekend(newDate) ? parseFloat(this.room.price_weekend) : (parseFloat(this.room.price_weekday)) || parseFloat(this.room.price))
            let activePrice = 'Default'
            let activeAvailability = parseInt(this.room.room_total || 0) || 0
            const foundPricing = this.data.pricing.find(r => r.start === newDate && r.isPrice)
            if (foundPricing) {
              activePrice = (foundPricing.name || '').replace('Rp', '').replace('.00', '').replace(',', '').replace(',', '').replace(',', '')
              activeAvailability = parseInt(foundPricing.roomTotal) || 0
            }
            const newData = {
              price_date: newDate,
              availability: activeAvailability,
              price: activePrice,
              activePrice: foundPricing || null
            }
            this.dialogBulkUpdate.dataTemp.push(Object.assign({}, newData))
            this.dialogBulkUpdate.data.push(Object.assign({}, newData))
          }
        }
      }
      this.dialogBulkUpdate.show = true
    },
    BulkUpdateProcess () {
      // OPEN BULK UPDATE BY LIST DATES
      if (!confirm('Update Prices ?')) {
        return false
      }
      if (this.dialogBulkUpdate.loading) {
        return false
      }
      const priceToUpdate = this.dialogBulkUpdate.data
      const data = []
      for (let d = 0; d < priceToUpdate.length; d++) {
        if (parseInt(priceToUpdate[d].price) || parseInt(priceToUpdate[d].availability)) {
          data.push(Object.assign(priceToUpdate[d], { price: parseInt(priceToUpdate[d].price) }, { availability: parseInt(priceToUpdate[d].availability) }))
        } else {
          // 0 for Delete existing price
          data.push(Object.assign(priceToUpdate[d], { price: 0 }))
        }
      }
      this.dialogBulkUpdate.loading = true
      this.$store.dispatch('room/PRICE_BY_DATE_BULK_PROCESS', {
        hotel: parseInt(this.$store.state.storeActive),
        room: parseInt(this.room.id),
        data
      })
        .then((res) => {
          this.dialogBulkUpdate.loading = false
          if (res.status) {
            this.dialogBulkUpdate.show = false
            this.$store.dispatch('TOAST', { show: true, message: 'Updated!' })
            this.REFRESH_CALENDAR_DATA()
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Something went wrong!' })
          }
        })
    },
    UpdatePrice () {
      const defaultPrice = this.openDateEvent ? (this.openDateEvent.isPrice && parseInt(this.room.id) ? parseFloat(this.openDateEvent.name.replace('Rp', '').replace('.00', '').replace(',', '').replace(',', '')) : 0) : 0
      if (!parseInt(this.priceToUpdate)) {
        if (!parseInt(this.openDateEvent.priceId)) {
          this.$store.dispatch('TOAST', { show: true, message: 'Already at the default price!' })
          this.priceToUpdate = defaultPrice
          return false
        } else if (!confirm('Set price to 0 will remove the price and reset to default price!')) {
          return false
        }
        this.$store.dispatch('room/PRICE_BY_DATE_DELETE', {
          id: parseInt(this.openDateEvent.priceId),
          hotel: parseInt(this.$store.state.storeActive),
          room: parseInt(this.room.id)
        })
          .then((res) => {
            if (res.status) {
              this.$store.dispatch('TOAST', { show: true, message: 'Deleted!' })
              this.openDateMenu = false
              this.priceToUpdate = 0
              this.REFRESH_CALENDAR_DATA()
            } else {
              this.$store.dispatch('TOAST', { show: true, message: 'Something went wrong!' })
            }
          })
      } else {
        if (parseInt(this.priceToUpdate) === parseInt(defaultPrice)) {
          this.$store.dispatch('TOAST', { show: true, message: 'Already at the default price!' })
          return false
        }
        this.$store.dispatch('room/PRICE_BY_DATE_PROCESS', {
          hotel: parseInt(this.$store.state.storeActive),
          room: parseInt(this.room.id),
          price_date: this.openDateEvent.start,
          price: this.priceToUpdate
        })
          .then((res) => {
            if (res.status) {
              this.$store.dispatch('TOAST', { show: true, message: 'Updated!' })
              this.openDateMenu = false
              this.priceToUpdate = 0
              this.REFRESH_CALENDAR_DATA()
            } else {
              this.$store.dispatch('TOAST', { show: true, message: 'Something went wrong!' })
            }
          })
      }
    },
    async REFRESH_CALENDAR_DATA () {
      this.events = []
      const thisMonth = this.$getThisMonth(this.selectedMonth)
      if (this.booking.hotel) {
        // ONLY PROCESS FOR BOOKING CALENDAR
        return false
      }

      this.data.pricing = []
      await this.$store.dispatch('room/PRICE_BY_DATE_GET', '?room=' + (this.room.id || 0) + '&from=' + thisMonth.start + '&to=' + thisMonth.end)
        .then((res) => {
          if (res.status) {
            const d = res.data.data || []
            for (let e = 0; e < d.length; e++) {
              const countPriceActive = this.$countPriceActive(
                d[e].price_date,
                parseFloat(d[e].price),
                parseFloat(this.room.price_weekend),
                parseFloat(this.room.price_weekday),
                parseFloat(this.room.price)
              )
              this.data.pricing.push({
                isPrice: true,
                roomTotal: parseInt(d[e].availability || 0) || parseInt(this.room.room_total || 0) || 0,
                priceId: d[e].id,
                priceActive: countPriceActive.type,
                name: this.$price(countPriceActive.price),
                start: d[e].price_date,
                end: d[e].price_date,
                color: 'grey',
                timed: true
              })
            }
          }
        })

      this.data.schedules = []
      await this.$store.dispatch('room/ROOM_SCHEDULES', '/' + (this.room.hotel || 0) + '?room=' + (this.room.id || 0) + '&from=' + thisMonth.start + '&to=' + thisMonth.end)
        .then((res) => {
          if (res.status) {
            const d = res.data.data || []
            for (let e = 0; e < d.length; e++) {
              const s = parseInt(d[e].scheduled) || 0
              const findAvailability = this.data.pricing.find(r => r.start === d[e].dt)
              const availability = parseInt(findAvailability ? parseInt(findAvailability.roomTotal || 0) || parseInt(this.room.room_total || 0) || 0 : parseInt(this.room.room_total || 0))
              this.data.schedules.push({
                name: (s * 100 / availability).toFixed(1),
                roomTotal: availability,
                start: d[e].dt,
                end: d[e].dt,
                color: 'grey',
                timed: true,
                scheduled: parseInt(d[e].scheduled)
              })
            }
          }
        })
      await this.PARSING_CALENDAR_DATA()
    },
    async PARSING_CALENDAR_DATA () {
      this.events = []
      const thisMonth = this.$getThisMonth(this.selectedMonth)
      const days = this.$DateRangeDays(thisMonth.start, thisMonth.end)
      for (let d = 0; d < (days + 1); d++) {
        const day = thisMonth.start.substring(0, 8) + ((d + 1).toString().padStart(2, '0'))

        const countPriceActive = this.$countPriceActive(
          day,
          0,
          parseFloat(this.room.price_weekend),
          parseFloat(this.room.price_weekday),
          parseFloat(this.room.price)
        )

        const toAddPricing = {
          isPrice: true,
          name: this.$price(countPriceActive.price),
          roomTotal: parseInt(this.room.room_total || 0),
          priceActive: countPriceActive.type,
          start: day,
          end: day,
          color: 'grey',
          timed: true
        }
        const toAddSchedule = {
          name: (0 * 100 / parseInt(this.room.room_total || 0)) || 0,
          roomTotal: parseInt(this.room.room_total || 0),
          start: day,
          end: day,
          color: 'grey',
          timed: true,
          scheduled: 0
        }

        const findPricingAndAvailability = this.data.pricing.find(r => r.start === day)
        const findSchedule = this.data.schedules.find(r => r.start === day)
        if (findPricingAndAvailability) {
          if (!findSchedule && parseInt(findPricingAndAvailability.roomTotal)) {
            toAddSchedule.roomTotal = parseInt(findPricingAndAvailability.roomTotal)
          }
        }
        this.events.push(Object.assign({}, findPricingAndAvailability || toAddPricing))
        this.events.push(Object.assign({}, findSchedule || toAddSchedule))
      }
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.openDateEvent = event
        this.priceToUpdate = event ? (event.isPrice && parseInt(this.room.id) ? parseFloat(event.name.replace('Rp', '').replace('.00', '').replace(',', '').replace(',', '')) : 0) : 0
        this.openDateElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => {
          this.openDateMenu = true
        }))
      }
      if (this.openDateMenu) {
        this.priceToUpdate = 0
        this.openDateMenu = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    updateRange ({ start, end }) {
      this.selectedMonth = start.date
      if (parseInt(this.room.id)) {
        this.REFRESH_CALENDAR_DATA()
      }
    },
    ListOfYear () {
      const year = (new Date(this.selectedMonth).getFullYear())
      const data = []
      const d = [
        { text: 'Januari 2022', value: '2022-01-01' },
        { text: 'Februari 2022', value: '2022-02-01' },
        { text: 'March 2022', value: '2022-03-01' },
        { text: 'April 2022', value: '2022-04-01' },
        { text: 'May 2022', value: '2022-05-01' },
        { text: 'June 2022', value: '2022-06-01' },
        { text: 'July 2022', value: '2022-07-01' },
        { text: 'August 2022', value: '2022-08-01' },
        { text: 'September 2022', value: '2022-09-01' },
        { text: 'October 2022', value: '2022-10-01' },
        { text: 'November 2022', value: '2022-11-01' },
        { text: 'December 2022', value: '2022-12-01' }
      ]
      for (let y = 0; y < d.length; y++) {
        const newD = new Date(d[y].value)
        newD.setFullYear(year)
        data.push({
          text: this.$MonthName(d[y].value, true) + ' ' + year,
          value: this.$localDT(newD)
        })
      }
      return data
    },
    formatDate (date) {
      if (!date) { return null }
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    }
  }
}
</script>

<style lang="scss">
.booking-filter-month {
  .v-input {
    min-width: 200px;
    >.v-input__control {
      min-height: 35px!important;
      .v-select__slot {
        .v-select__selections {
          .v-select__selection {
            width: 100%;
            margin: 0px!important;
            padding-left: 5px;
            font-size: 1rem;
          }
          input {
            display: none;
          }
        }
      }
    }
  }
}
.select-calendar-month {
  button {
    &.v-icon {
      font-size: 20px!important;
      color: #64007d!important;
    }
  }
}
</style>
